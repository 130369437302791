<template>
  <DivisiForm mode="Tambah" module="Divisi"> </DivisiForm>
</template>

<script>
import DivisiForm from './form';

const DivisiAdd = {
  name: 'DivisiAdd',
  components: { DivisiForm },
};

export default DivisiAdd;
</script>
